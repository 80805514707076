.EditorToolbar__root___3_Aqz {
  display: none;
}
.configuration {
  height: auto;
  background-color: white;
  margin: 15px;
  overflow: auto;

  &--loading {
    @include loading-icon;
  }

  &__spinner-container {
    min-height: 100%;
    background-color: #ffffff;
    margin-top: 15rem;
  }

  &__text-area-label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &__text-area {
    width: 75%;
    height: 150px;
    overflow: auto;
    padding: 9px 12px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Open Sans";
    resize: none;
    outline-color: #007cb0;
  }

  &__required::after {
    content: " *";
    color: red;
  }

  flex-direction: column;
  display: flex;
  justify-content: space-between;

  &__save-continue {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 3rem;
    box-shadow: inset 0px 1px 0px #e0e0e0;
    padding: 18px 0px;

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__user-country {
    padding: 12px 20px 5px 15px !important;
    width: 319px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 2px;
    padding: 10px;
    height: 30px;
    opacity: 0.6;
    color: #000000;
  }

  &__submit-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }

  &__continue {
    @include font-family;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }
  &__utilization-container {
    margin-top: 10px;
    &--tool_tip_icon {
      display: flex;
    }
    &--icon-div {
      margin-left: 0.5rem;
      margin-top: 1.2rem;
    }
    &--input {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 0.5rem;
      padding: 0.5rem 1rem;
      border-radius: 0.25rem;
      min-height: 1.65rem;
      min-width: 20rem;
      font-family: "Open Sans";
      font-size: 16px;

      &--default {
        background-color: #fff;
        border: 1px solid #d0d0ce;
        outline-color: #007cb0;
      }
      &--error {
        background-color: #fff;
        border: 1px solid red;
        outline-color: red;
      }
      &--disable {
        background-color: rgb(223, 223, 223);
        border: 1px solid #d0d0ce;
      }
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 4rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &--input {
    padding: 20px;
  }

  .radio-class {
    //   float: left;
    margin-top: 15px;
  }

  .inputLabel {
    float: left;
    clear: none;
    display: block;
    padding: 0px 1em 0px 8px;
    cursor: pointer;
  }

  input.radio {
    float: left;
    clear: none;
  }
}
