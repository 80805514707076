.uploadDropZone {
  &__main {
    width: 600px;
    height: 450px;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    position: relative;
  }
  &__container,
  &__container-hover {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 42px 20px 20px 20px;
    border-width: 2px;
    border-color: #007cb0;
    border-style: dashed;
    background-color: #f1f8fb;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
  }

  &__container-hover {
    border-style: groove;
    border-color: #007cb0;
  }
  &__information-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 8px;
    position: absolute;
    width: 592px;
    height: 88px;
    top: 24px;
    background: #ebf8fd;
    border: 1px solid #00a3e0;
    border-radius: 2px;
  }
  &__information {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 560px;
    height: 24px;
  }
  &__information-text {
    width: 528px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  &__information-icon {
    margin-top: 3px;
  }

  &__primary-text {
    width: 560px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
  }

  &__secondary-text {
    width: 560px;
    height: 16px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #53565a;
  }

  &__select-text {
    color: #007cb0;
    cursor: pointer;
  }

  &__button-container {
    display: flex;
    justify-content: end;
    position: absolute;
    bottom: 0px;
    width: inherit;
  }

  &__upload-button,
  &__cancel-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 16px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #007cb0;
    color: #007cb0;
    margin: 20px 10px 2px 10px;
    cursor: pointer;
  }
  &__upload-button {
    margin-right: 0px;
    background: #007cb0;
    color: #ffffff;
  }

  &__upload-button-disabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 16px;
    height: 36px;
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #c2c2c2;
    margin: 20px 0px 2px 10px;
  }

  &__upload-section {
    width: 600px;
    height: 350px;
  }

  &__upload-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__upload-text {
    margin: 0px;
    margin-left: 10px;
  }

  &__upload-cross {
    cursor: pointer;
  }

  &__xlsm {
    display: flex;
  }

  &__files {
    display: flex;
    margin-top: 12px;
    align-items: center;
    justify-content: space-between;
  }

  &__files-name {
    display: flex;
    align-items: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }

  &__upload-file-error {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #da291c;
  }

  &__icon {
    margin-right: 5px;
  }
  &__delete-icon {
    margin-top: -3px;
    margin-right: 5px;
    cursor: pointer;
  }
  &__guidance-container {
    border-radius: 2px;
    padding: 10px;
    max-height: 125px;
    overflow: auto;
  }
  &__guidance-container-filename {
    border-radius: 2px;
    padding: 10px;
    max-height: 95px;
    overflow: auto;
  }
  &__guidance-icon {
    margin-top: -5px;
  }
  &__guidance-header {
    display: flex;
    align-items: center;
    max-width: 100px;
    justify-content: space-between;
    margin-top: 12px;

    &--text {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
    }
  }
  &__guidance-text-header {
    margin: 6px 6px 6px 26px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__guidance-text {
    margin-left: 6px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #212121;
  }

  &__confirmation-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__confirmation-modal-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__validation-info-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-left: 8px;
    padding-bottom: 8px;
    gap: 8px;
    background: #ebf8fd;
    box-shadow: inset 0px -1px 0px #00a3e0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  &__validatio-info-icon {
    margin-top: -7px;
  }

  &__validation-info-summary {
    width: 576px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  &__validation-box {
    overflow: auto;
    height: 350px;
  }
  &__validation-list-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 595px;
    max-height: 100px;
    padding: 0px;
    margin-top: 14px;
    background: #ffffff;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__validation-table-name {
    width: 48%;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #212121;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &__validation-error-msg-container {
    display: flex;
    width: 48%;
    flex-direction: column;
    align-items: baseline;
    gap: 10px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &__validation-error-icon {
    margin-top: -12px;
    margin-right: 5px;
    margin-left: 5px;
  }
  &__validation-error-msg {
    width: 100%;
    max-height: 100px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: baseline;
    color: #212121;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
}
