.AddNewGeo {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 14px;

  &__required::after {
    content: " *";
    color: red;
  }

  &__header {
    padding-left: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: inset 0px -1px 0px $lightest-grey;
  }

  &__start-new-section {
    min-width: 7rem;
  }

  &__start-new-button {
    @include invisible-button;
    display: flex;
    align-items: center;
    padding: 0.5rem 0.2rem 0.5rem 1.25rem;
    color: $primary;
  }
  &__feedback-section {
    min-width: 1rem;
  }
  &__feedback-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1rem 0.5rem 0.5rem;
    border: none;
    margin-right: 1.8rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }

  &__commenting {
    margin-right: 0.4rem;
  }
  &__results-actions {
    display: flex;
  }

  &__export-button {
    @include primary-button-outline;
    @include font-family;
    //height: 36px;
    align-items: center;
  }
  &__save-search-button {
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    &--disabled {
      background-color: #d0d0ce;

      border: #75787b;
      border-radius: 0.25rem;
      color: #75787b;
      cursor: pointer;

      path {
        fill: #75787b;
      }
    }
  }

  &__back-to-search {
    padding: 0.5rem 1.5rem;
    margin-right: 1rem;
    border: none;
    background: none;
    text-decoration: underline;
  }

  &__content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  /*
   * Filters
   */

  &__filter-side {
    display: flex;
    flex-direction: column;
    width: 1rem;
    padding: 1rem;
    background-color: $lightest-grey;
    cursor: pointer;
    position: relative;
  }

  &__show-filters {
    margin-bottom: 0.5rem;
    @include invisible-button;
  }

  &__filter-span {
    font-weight: 600;

    &--hidden {
      transform: rotate(90deg);
    }
  }

  &__active-filters {
    margin-left: 0.5rem;
    color: $white;
    background-color: $red;
    font-size: 10px;
    border-radius: 1rem;
    padding: 0rem 0.3rem;

    &--hidden {
      position: absolute;
      top: 0.5rem;
    }
  }

  &__filter-options {
    display: flex;
    flex-direction: column;
    width: 22rem;
    padding: 0px 25px 15px 15px;
    margin-top: -20px;
  }

  &__filter-header {
    display: flex;
    justify-content: space-between;
  }

  &__filter-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    max-height: 511px;
  }
  &__filter-edit-geo-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 350px;
    overflow: hidden;
  }
  &__filter-small-edit-geo-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 260px;
    overflow: hidden;
  }

  &__filter-scroll {
    flex-grow: 1;
    margin-top: 1.5rem;
    margin-right: -1.5rem;
    padding-right: 0.5rem;
    align-items: baseline;
    position: relative;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  &__hide-filters {
    @include invisible-button;
  }

  &__drop-down {
    margin-top: 1rem;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }

  &__drop-down-label {
    display: flex;
  }

  &__drop-down-icon {
    margin-left: 0.25rem;
    min-width: 2.5rem;

    .tooltip {
      @include white-tooltip;
    }
    svg {
      cursor: pointer;
    }

    &:hover {
      svg {
        background-color: $light-grey;
        border-radius: 100%;
      }

      path {
        fill: $black;
      }

      .tooltip {
        display: inline-block;
      }
    }
  }

  &__filter-buttons-container {
    margin-top: 1.5rem;
    display: flex;
    justify-content: end;
  }

  &__filter-buttons-container .primary-outline {
    margin-right: 20px !important;
  }

  &__submit-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }

  &__filter-search-icon {
    padding-right: 0.25rem;

    & path {
      fill: $white;
    }
  }

  &__reset-button {
    @include invisible-button;
    display: flex;
    align-items: center;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    color: $primary;
  }

  /*
   * Search Results
   */

  &__results-content {
    display: flex;
    width: 100%;
  }

  &__results-section {
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    display: flex;

    &--filled {
      margin-top: 1rem;
      width: 100%;
      flex-direction: column;
    }
  }

  &__results {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 39rem;
  }

  &__empty-image {
    max-width: 13.5rem;
    margin-bottom: 1.188rem;
  }

  &__empty-title {
    font-weight: 600;
  }

  &__empty-message {
    font-size: 0.875rem;
    color: $grey;
  }

  &__link-button {
    @include invisible-button;
    color: $primary;
    text-decoration: underline;
    font-size: 0.75rem;
  }

  &__results-container {
    width: 100%;
    border: 1px solid $lightest-grey;
    box-shadow: 0 0.25rem 0.5rem rgba(238, 240, 241, 0.48);
    border-radius: 0rem 0.5rem 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;

    &--show-document-viewer {
      width: 60%;
      border-radius: 0.5rem 0rem 0.5rem 0.5rem;

      .tabbed-content__item {
        padding: 0.875rem 1rem;
      }
    }
  }

  &__results-summary {
    border-bottom: 1px solid $secondary-light-grey;
    padding: 0.6rem 1rem;
    font-size: 1rem;
  }

  &__results-breadcrumb {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  &__report-breadcrumb {
    margin-left: 0.25rem;
    color: $grey;
  }

  &__tab-panel {
    flex-grow: 1;
    display: none;

    &--selected {
      display: flex;
    }
  }

  &__table {
    position: absolute;
  }

  &__table-wrapper {
    height: 26.25rem;
    overflow-y: auto;
    flex-grow: 1;
    position: relative;
  }

  &__total-results {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    margin-bottom: 1rem;
    align-items: center;
  }
  &__column-company-name {
    min-width: 9rem;
  }

  &__column-document-type {
    width: 5%;
  }
  &__column-document-type-sec {
    width: 15%;
  }

  &__column-period-sec {
    width: 15%;
  }
  &__column-period {
    min-width: 6rem;
  }

  &__column-report-title {
    min-width: 13rem;
  }

  &__column-report-rating {
    width: 11%;
    :hover {
      text-decoration: underline;
    }
  }

  &__column-report-score {
    width: 15%;
    :hover {
      text-decoration: underline;
    }
  }

  &__column-icon {
    width: 1.5rem;
  }

  &__tabs {
    display: flex;

    .tabbed-content {
      box-shadow: none;
      margin-bottom: -1px;
      z-index: 1;

      &__item {
        border: 1px solid $lightest-grey;
        background-color: #f7f7f7;
        color: $grey;
        font-weight: 600;
        margin-right: 0.5rem;
        border-radius: 0.5rem 0.5rem 0 0;

        &--selected {
          border-bottom: none;
          background-color: $white;
          color: $black;
          padding-bottom: 0.75rem;
        }
      }
    }
  }
  &__compare-button {
    position: relative;
    @include invisible-button;
    color: #007cb0;
    padding-right: 1rem;

    font-size: 14px;
    line-height: 24px;
    &--disabled {
      color: #75787b;
      cursor: pointer;
      path {
        fill: #75787b;
      }
    }
    .tooltip {
      left: -95%;
      top: -125%;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__period-years-cell {
    display: flex;
    align-items: center;
    &-text {
      position: relative !important;
      .tooltip {
        display: none !important;
        top: -1.3rem;
        left: 0;

        &__arrow {
          bottom: -1.8rem;
          left: 1rem;
        }
        &__label {
          bottom: -1.5rem;
          position: absolute;
          max-width: 4rem;
          left: 1rem;
        }
      }
      &:hover {
        .tooltip {
          display: inline-block !important;
        }
      }
    }
    &-btn {
      background-color: transparent;
      border: none;
      height: fit-content;
      font-size: 14px;
      font-family: "Open Sans";
      cursor: pointer;
      &:hover {
        background: #e0e0e0;
        border-radius: 15px;
      }
    }
  }

  &__preparer-list-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__preparer-list {
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #f0f0f0;
    border-radius: 100px;
    padding: 4px 4px 4px 12px;
    width: fit-content;
    margin: 5px 4px;
  }
  &__preparer-reviewer-same {
    border: 1px solid red;
  }
  &__text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
  }
  &__delete-icon {
    margin-top: 4px;
    margin-left: 6px;
    cursor: pointer;
  }
}
