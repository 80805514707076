.visual_dashboard {
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  width: 100%;
  &__link {
    color: $primary;
    font-size: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: inline;
    margin: 0;
    padding: 0;
  }
  &__spinner-container {
    background-color: #ffffffd6;
    position: absolute;
    z-index: 200;
    width: 96%;
    padding-top: 18%;
    height: 50%;
    display: flex;
  }
  &--loading {
    animation-name: spin;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    margin-top: -5px;
    margin-bottom: auto;
    margin-left: 45%;
    margin-right: 5px;
  }
  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 60px;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--refresh {
      &--btn {
        background: none;
        border: none;
        position: relative;

        &--graphView {
          margin-top: 3px;
        }
        &--enabled {
          cursor: pointer;
        }

        &--disabled {
          cursor: not-allowed;
        }
      }
    }
    &--redDot {
      height: 1rem;
      width: 1rem;
      background-color: #da291c;
      border-radius: 50%;
      position: absolute;
      right: 1px;
      top: -5px;
    }

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #000000;
      width: 100%;
      padding-left: 1.5rem;
    }
  }
  &__dashboard-buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 25px;
    height: 36px;
    flex: none;
    order: 3;
    flex-grow: 0;
    margin-top: 12px;
    margin-right: 1.8rem;
  }
  &__icons-shift {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;

    width: 72px;
    height: 36px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__table-selected-icon {
    width: 36px;
    height: 36px;
    background: #007cb0;
    border-radius: 2px 0px 0px 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__table-un-selected-icon {
    width: 36px;
    height: 34px;
    background: #ffffff;
    border-radius: 2px 0px 0px 2px;
    border: 1px solid #007cb0;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__garph-selected-icon {
    box-sizing: border-box;

    width: 36px;
    height: 36px;
    background: #007cb0;
    border: 1px solid #007cb0;
    border-radius: 0px 2px 2px 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &__garph-un-selected-icon {
    box-sizing: border-box;

    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #007cb0;
    border-radius: 0px 2px 2px 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &__shift-icon2 {
    margin-top: 20%;
    margin-left: 13%;
  }
  &__shift-icon1 {
    margin-top: 13%;
    margin-left: 16%;
  }
  &__export-section {
    margin-top: 0.2rem;
  }
  &__export {
    cursor: pointer;
    width: 54px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #007cb0;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  &__metrics {
    display: flex;
    margin-left: auto;
  }

  &__metrics-dashboard-button {
    @include font-family;
    background-color: $secondary-light-grey;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    border-radius: 0.25rem;
    color: $black;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
    height: 36px;
    margin-top: -4px;
  }
  &__metrics-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    margin-right: 1.8rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
    height: 36px;
  }
}

.consolidatedData {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 175px);
  box-sizing: border-box;
}
