.kebab-menu {
  position: relative;
  margin-right: 12px;
}

.kebab-icon {
  cursor: pointer;
}

.menu-popup {
  position: absolute;
  z-index: 9999;
  width: 9.1rem !important;
  top: 20px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 4px 0;
}

.menu-popup-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  &--disabled {
    cursor: not-allowed !important;
  }
}

.menu-popup-ul-li {
  padding: 8px 16px;
  cursor: pointer;
  &--disabled {
    background: rgb(190, 189, 189);
    cursor: not-allowed !important;
    opacity: 0.5;
  }
  &--disabled:hover {
    background: rgb(190, 189, 189) !important;
    cursor: not-allowed !important;
    opacity: 0.5;
  }
}

.menu-popup-ul-li:hover {
  background-color: #f2f2f2;
}
