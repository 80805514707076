.RollForward {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 14px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #e9e9e9;
  border-radius: 2px 2px 0px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;

  &__submit-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem 1.5rem 0.5rem 1.25rem;
    border: none;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
  }
  &__content {
    width: 396px;
    height: 224px;
    flex: none;
    order: 1;
    flex-grow: 0;
  }
  &__contentText {
    position: relative;
    width: 364px;
    height: 72px;
    left: 16px;
    top: 12px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
  }
  &__checkBoxContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    position: relative;
    width: 364px;
    height: 48px;
    left: 16px;
    top: 50px;
  }
  &__buttonsLayout {
    width: 396px;
    height: 52px;
    background: #ffffff;
    box-shadow: inset 0px 1px 0px #e0e0e0;
    border-radius: 0px 0px 2px 2px;
    flex: none;
    order: 2;
    flex-grow: 0;
    position: relative;
  }
  &__buttonsContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    position: absolute;
    right: 16px;
    top: 16px;
  }
  &__rollForwardButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    gap: 16px;
    height: 34px;
    background: #007cb0;
    border-radius: 0.25rem;
    flex: none;
    order: 3;
    align-self: flex-start;
    flex-grow: 0;
    color: $white;
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 0.875rem;
    border: none;
    cursor: pointer;
  }
  &__rollForwardButtonDisable {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    gap: 16px;
    height: 34px;
    background: #f0f0f0;
    border-radius: 0.25rem;
    flex: none;
    order: 3;
    align-self: flex-start;
    flex-grow: 0;
    font-weight: 400;
    line-height: 1.5rem;
    color: #c2c2c2;
    border: none;
  }
  &__cancelDisabled {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    padding-top: 0.5rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    gap: 16px;
    height: 34px;
    background: #f0f0f0;
    border-radius: 0.25rem;
    flex: none;
    order: 2;
    align-self: flex-start;
    flex-grow: 0;
  }
  &__progressTextContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    left: 16px;
    padding: 0px;
    gap: 4px;
    width: 364px;
    height: 24px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  &__progressText {
    width: 336px;
    height: 24px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #212121;
    flex: none;
    order: 1;
    flex-grow: 1;
  }
  &__sucessIcon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    flex: none;
    order: 2;
    flex-grow: 0;
  }
  &_progressContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 0px 0px;
    gap: 6px;
    position: relative;
    height: 40px;
    left: 16px;
    right: 16px;
    width: 364px;
  }
  &__drop-down-container {
    width: 355px;
    margin: 10px 20px;
  }
}
