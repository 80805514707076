.DeleteModal {
  width: 25rem;
  display: flex;
  flex-direction: column;

  &__body {
    padding: 0 1rem;
  }

  &__name {
    font-size: 0.875rem;
    line-height: 1.5rem;
    color: $black;
    font-weight: 500;
  }

  &__options {
    padding-top: 2rem;
    text-align: right;
  }

  &__delete-button {
    margin-left: 1rem;
  }
}
