.attentionPopup {
  width: 30rem;
  height: auto;

  &__content {
    &--text {
      margin-left: 1rem;
      margin-right: 1rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 1rem;
    }

    &--footer {
      &--actions {
        display: flex;
        flex-direction: row;
        height: 2.5rem;
        padding-top: 1.25rem;
        margin-left: 15rem;

        :hover {
          cursor: pointer;
        }

        &--warning {
          display: flex;
          flex-direction: row;
          height: 2.5rem;
          padding-top: 1rem;
          margin-left: 15rem;

          :hover {
            cursor: pointer;
          }
        }

        &--confirm {
          margin-left: 1rem;
          background: #007cb0;
          border: none;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          min-width: 6rem;
        }

        &--cancel {
          margin-left: 1rem;
          background: #ffffff;
          border: 1px solid #007cb0;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #007cb0;
          min-width: 6rem;
        }
      }
    }
  }
}
