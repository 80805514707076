@mixin font-family {
  font-family: "Open Sans";
}

@mixin invisible-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
}

@mixin invisible-button-disabled {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: $grey;
}

@mixin blank-link {
  text-decoration: none;
  color: unset;
}

@mixin primary-button {
  padding: 0.5rem 1rem;
  background-color: $primary;
  border: none;
  color: $white;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-rounded {
  padding: 0.5rem 1rem;
  background-color: $primary;
  border: none;
  color: $white;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-disabled {
  padding: 0.5rem 1rem;
  background-color: $light-grey;
  border: $grey;
  color: $grey;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-outline {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-outline-disabled {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $grey;
  color: $grey;
  cursor: pointer;
  border-radius: 0.25rem;
}

@mixin primary-button-outline-rounded {
  padding: 0.5rem 1rem;
  background-color: $white;
  border: 1px solid $primary;
  color: $primary;
  cursor: pointer;
  border-radius: 1rem;
}

@mixin primary-button-rounded {
  padding: 0.5rem 1rem;
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
  cursor: pointer;
  border-radius: 1rem;
}

@mixin loading-icon {
  animation-name: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin: auto;
}

@mixin white-tooltip {
  left: 30%;
  top: -30%;
  z-index: 3;
  display: none;

  &__label {
    max-width: 23rem;
    text-align: left;
    background-color: $white;
    color: $black;
    padding: 1rem;
    border: 1px solid $light-grey;
    position: fixed;
  }

  &__arrow {
    display: none;
  }
}
