.consolidatedTableData {
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;

  &__scroll_margin {
    background-color: white;
    scroll-margin-top: 70px;
  }
  &__scroll_margin_head_counts {
    background-color: white;
    scroll-margin-top: 98px;
  }
  &--container-with-panel {
    width: 75%;
  }
  &--container-without-panel {
    width: 100%;
  }

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 4rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__subHeader {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 2.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
    &--noteBold {
      font-family: "Open Sans";
      font-style: bold;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
    &--note {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 0.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__subHeaderlargeNote {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 90px;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &--largeNote {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
  }

  &__common-dashboard {
    margin-left: 15px;
    margin-right: 15px;
  }
  &__common-dashboard-margin {
    margin-top: 20px;
  }
  &__SticyHead {
    position: sticky;
    top: 4rem;
    z-index: 2;
  }
  &__ThPadding {
    padding-left: 5rem;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px #e0e0e0;
  }
  &__divider-header {
    display: flex;
    background-color: $white;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    position: -webkit-sticky;
    top: 67px;
    left: 0;
    z-index: 2;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
  }
}
.scrollContainer_dashboard {
  background-color: #f5f4f4;
  overflow: auto !important;
  height: calc(100vh - 200px);
}
.__indexKey {
  box-shadow: none !important;
}

.consolidation-indexKey {
  height: 1.5rem !important;
  font-weight: 600;
}
