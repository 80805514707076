.visual_graph {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-right: 2rem;
  width: 100%;
  overflow: auto;
  height: calc(100vh - 175px);
  box-sizing: border-box;
  &__header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    position: relative;
    height: 24px;
    padding-left: 3rem;
    right: 24px;
    top: 24px;
    &--title {
      width: auto;
      height: 24px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
      flex: none;
      order: 0;
      flex-grow: 1;
    }
    &--chevron {
      width: 24px;
      height: 24px;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
  &--loading {
    @include loading-icon;
  }
  &__spinner-container {
    margin-top: 15rem;
  }
  &__Graphs {
    display: flex;
    flex-direction: column;
  }
  &__NetRevenue {
    width: 100%;
    height: 600px;
    overflow-x: auto;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 1rem;
  }
  &__GMBridge {
    width: 100%;
    height: fit-content;
    background: #ffffff;
    border-radius: 0px 0px 8px 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 2rem;
  }
  &__Label {
    width: 31px;
    height: 12px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    text-align: right;

    /* Neutral / Black 54% */

    color: #757575;
  }
  &__Label1 {
    width: 80px;
    height: 12px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height, or 100% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutral / Black 54% */

    color: #757575;
  }

  &__LabelGMBridge {
    width: 80px;
    height: 18px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    color: #212121;
  }
  &__LabelList {
    width: 31px;
    height: 18px;

    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Neutral / Black 87% */

    color: #212121;
  }

  &__GraphRow {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;

    width: auto;
    height: 358px;
    margin-bottom: 1rem;
    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;

    &--GraphBox {
      box-sizing: border-box;

      width: calc(50% - 8px);
      height: 358px;

      background: #ffffff;
      /* Neutral/Black 6% */

      border: 1px solid #f0f0f0;
      border-radius: 8px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;
    }
  }
  &__legend {
    display: flex;
    align-items: center;
    &__omniaUSI {
      width: 12px;
      height: 4px;
      background: #c4d600;
      margin-right: 4px;
    }
    &__omniaRADC {
      width: 12px;
      height: 4px;
      background: #43b02a;
      margin-right: 4px;
    }
    &__levvia {
      width: 12px;
      height: 4px;
      background: #2a5599;
      margin-right: 4px;
    }
    &__tier5 {
      width: 12px;
      height: 4px;
      background: #a0dcff;
      margin-right: 4px;
    }
    &__assurance {
      width: 12px;
      height: 4px;
      background: #00abab;
      margin-right: 4px;
    }
    &__other {
      width: 12px;
      height: 4px;
      background: #6fc2b4;
      margin-right: 4px;
    }
    &__total {
      width: 8px;
      height: 8px;
      background: #2a5599;
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;
    }
    &__decrease {
      width: 8px;
      height: 8px;
      background: #e35a0e;
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;
    }
    &__increase {
      width: 8px;
      height: 8px;
      background: #62b5e5;
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;
    }
    &__fee {
      width: 8px;
      height: 8px;
      background: #00abab;
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;
    }
    &__cost {
      width: 8px;
      height: 8px;
      background: #3984b6;
      display: inline-block;
      margin-right: 4px;
      border-radius: 50%;
    }
  }

  &__legends {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 0px;
    width: 212px;
    height: 18px;
    margin-top: 40px;
    margin-left: 35px;
    &-GMBridge {
      justify-content: space-evenly;
      margin-left: 0;
    }
    &--name {
      width: 28px;
      height: 18px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #212121;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    &--name1 {
      width: 150px;
      height: 18px;

      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #212121;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    &--name2 {
      width: 90px;
      height: 18px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #212121;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
    &--name3 {
      width: 60px;
      height: 18px;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #212121;
      flex: none;
      order: 1;
      flex-grow: 0;
    }
  }
}
