.submitReviewModal {
    width: 30rem;
    height: 15rem;

    &__content {
        &--text {
            margin-left: 1rem;
            margin-right: 1rem;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
        }

        &--footer {
            margin-top: 8rem;
            height: 5rem;
            box-shadow: inset 0px 1px 0px #E0E0E0;
            &--actions {
                display: flex;
                flex-direction: row;
                height: 2.5rem;
                padding-top: 1.25rem;
                margin-left: 15rem;

                :hover {
                    cursor: pointer;
                }

                &--warning {
                    display: flex;
                    flex-direction: row;
                    height: 2.5rem;
                    padding-top: 0.5rem;
                    margin-left: 15rem;

                    :hover {
                        cursor: pointer;
                    }
                }

                &--confirm {
                    margin-left: 1rem;
                    background: #007CB0;
                    border: none;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #FFFFFF;
                    min-width: 6rem;
                }

                &--cancel{
                    margin-left: 1rem;
                    background: #FFFFFF;
                    border: 1px solid #007CB0;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #007CB0;
                    min-width: 6rem;
                }
            }
        }
    }
}