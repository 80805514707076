.footer {
  color: $grey;
  font-size: 0.7rem;
  padding: 0 0.5rem;
  z-index: 1;
  position: sticky;
  top: 100%;
  background-color: white;
  box-shadow: inset 0px 1px 0px #e0e0e0;
  &__link {
    color: $primary;
    font-size: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;
  }
}
