.checkbox-container {
  width: 100%;
  height: fit-content;
  margin-top: 1rem;

  &__header {
    font-weight: 600;
  }

  &__body {
    margin: 0.5rem 0 0 1rem;
  }

  &__checkbox-item {
    display: block;
  }

  &__checkbox-parent {
    margin-bottom: 0.5rem;
  }

  &__checkbox-parent-container {
    margin: 0 0 0 2rem;
    color: gray;
  }
}
