.headcounts {
  margin: 15px;
  height: calc(100% - 30px);
  flex-direction: column;
  display: flex;
  overflow: auto;
  scroll-behavior: smooth;

  &__spinner-container {
    display: flex;
    min-height: 800px;
    background-color: #ffffff;
  }

  &--loading {
    @include loading-icon;
  }

  &__sub-header {
    display: flex;
    background-color: $white;
    width: 100%;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &--title {
      display: flex;
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
      justify-content: space-between;
    }
    &--expand-icon {
      padding-left: 1rem;
      padding-top: 0.3rem;
      &:hover {
        cursor: pointer;
      }
    }

    &--icon {
      border: none;
      background: none;
      margin-right: 2rem;

      &:hover {
        cursor: pointer;
      }
    }
    &--toggle-icon {
      border: none;
      background: none;
      margin-left: 1rem;

      &:hover {
        cursor: pointer;
      }
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__divider-header {
    display: flex;
    background-color: $white;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 3;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__contentSpace {
    justify-content: space-between;
  }
  &__scroll-margin {
    scroll-margin-top: 4rem;
  }
}
