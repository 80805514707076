.liberatedHours {
  margin: 15px;
  height: calc(100% - 30px);
  flex-direction: column;
  display: flex;
  overflow: auto;

  &__container {
    background-color: #f7f7f7;
  }

  &__spinner-container {
    display: flex;
    min-height: 800px;
    background-color: #ffffff;
  }

  &--loading {
    @include loading-icon;
  }

  &__sub-header {
    display: flex;
    background-color: $white;
    width: 100%;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    &--title {
      display: flex;
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
      justify-content: space-between;
    }

    &--icon {
      border: none;
      background: none;
      margin-right: 2rem;

      &:hover {
        cursor: pointer;
      }
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__divider-header {
    display: flex;
    background-color: $white;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 2;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__footNote {
    text-align: end;
    background-color: #fff;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding: 8px;
    font-size: 0.875rem;
  }
}
