.refreshNotification {
  max-width: 400px;
  max-height: 220px;
  background-color: #ffff;
  border: 1px solid #e0e0e0;
  padding: 1rem;
  z-index: 999999;
  position: absolute;
  right: 15px;

  &__header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--text {
      font-weight: 600;
      size: 16px;
      font-family: "Open Sans";
    }
  }
  &__cross {
    cursor: pointer;
  }

  &__content-text {
    font-weight: 400;
    font-size: 14px;
    font-family: "Open Sans";
  }

  &__button-contanier {
    display: flex;
    justify-content: end;

    &--refresh-button {
      width: 86px;
      height: 36px;
      border-radius: 2px;
      padding: 6px;
      font-weight: 600;
      font-size: 14px;
      font-family: "Open Sans";
      cursor: pointer;
    }

    &--cancel {
      background: #ffff;
      border: 1px solid #007cb0;
      color: #007cb0;
    }
    &--refresh {
      background: #007cb0;
      border: 1px solid #007cb0;
      color: #ffffff;
      margin-left: 15px;
    }
  }
}
