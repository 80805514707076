.gmBridge {
  margin: 1rem;
  margin: 1rem 0rem;
  order: 1;

  &_container {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0rem;
    align-items: center;
  }

  &_section-header {
    font-weight: 600;
    font-size: 16px;
  }

  &_nav {
    display: flex;
    align-items: center;

    &--drop-down-label {
      display: flex;
    }
    &--item:first-child{
      margin-top:30px
    }
    &--item {
      width: fit-content;
      margin-left: 10px;
      margin-right: 10px;
      width: 250px;
    }
    &--item:last-child {
      margin-right: 0px;
      width: 25px;
    }
  }
  &__icons-shift {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  &__table-selected-icon {
    width: 7rem;
    height: 3rem;
    background: #007cb0;
    border-radius: 2px 0px 0px 2px;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-weight: 400;
    line-height: 1rem;
  }
  &__table-un-selected-icon {
    width: 7rem;
    height: 3rem;
    background: #ffffff;
    border-radius: 2px 0px 0px 2px;
    border: 1px solid #007cb0;
    flex: none;
    order: 0;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #007cb0;
    font-weight: 400;
    line-height: 1rem;
  }
  &__garph-selected-icon {
    box-sizing: border-box;

    width: 7rem;
    height: 3rem;
    background: #007cb0;
    border: 1px solid #007cb0;
    border-radius: 0px 2px 2px 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #fff;
    font-weight: 400;
    line-height: 1rem;
  }
  &__garph-un-selected-icon {
    box-sizing: border-box;

    width: 7rem;
    height: 3rem;
    background: #ffffff;
    border: 1px solid #007cb0;
    border-radius: 0px 2px 2px 0px;
    flex: none;
    order: 1;
    flex-grow: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: #007cb0;
    font-weight: 400;
    line-height: 1rem;
  }
}
