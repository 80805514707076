// Global variables
@import "./styleguide/variables";
@import "./styleguide/colors";
@import "./styleguide/animations";
@import "./styleguide/mixins";

@import "react-date-range/dist/styles.css"; // main css file
@import "react-date-range/dist/theme/default.css"; // theme css file

// Layout
@import "./components/app/layout";
@import "./components/footer/footer";

// Shared
@import "./components/shared/tooltip/tooltip";
@import "./components/shared/tooltip//tooltip.container";
@import "./components/shared/modal/modal";
@import "./components/shared/session-timeout-modal/session-timeout-modal";
@import "./components/shared/checkbox/checkbox";
@import "./components/shared/table/table";
@import "./components/shared/toast/toast";
@import "./components/shared/notification/notification";
@import "./components/shared/delete-modal/delete-modal";
@import "./components/shared/edit-modal/edit-modal";
@import "./components/shared/textfield/textfield";
@import "./components/shared/checkbox-container/checkbox-container";
@import "./components/shared/toggle/toggle";
@import "./components/shared/disclaimer-modal/disclaimer-modal";

// Components
@import "./components/unauthorized/unauthorized";
@import "./components/addNewGeo/_addNewGeo";
@import "./components/addNewGeo/_addnewgeofilter";
@import "./components/headcounts/headcounts";
@import "./components/headcounts/headcounts-table";
@import "./components/yearlyUtilization/yearlyUtilization-table.scss";
@import "./components/liberatedHours/liberatedHours.scss";
@import "./components/liberatedHours/liberatedHoursTable.scss";
@import "./components/liberatedHours/LiberatedHoursFinalTable.scss";
@import "./components/search/filters/drop-down";
@import "./components/header/header-new";
@import "./components/feedback/feedback";
@import "./components/side-menu/side-menu";
@import "./components/dashboard/dashboard";
@import "./components/visual_dashboard/visual_dashboard";
@import "./components/visual_dashboard/visual_graph";
@import "./components/visual_dashboard/consolidated_data_table";
@import "./components/visual_dashboard/consolidation-table";
@import "./components/visual_dashboard/metrics-model";
@import "./components/inputform/inputform";
@import "./components/configuration/configuration";
@import "./components/configuration/configurationPanel";
@import "./components/dashboard/dashboard-table.scss";
@import "./components/pdf-viewer/pdf-viewer";
@import "./components/slider/slider.styles";
@import "./components/downloads/downloads";
@import "./components/notifications/notifications";
@import "./components/shared/dropdown/dropdown";
@import "./components/shared/attention-popup/attentionPopup.scss";
@import "./components/inputform/kebabMenu.scss";
@import "./components/edit-geo/drop-down";
@import "./components/edit-geo/edit-geo";
@import "./components/roll-forward/roll-forward.scss";
@import "./components/revenue&PL/revenue&PL.scss";
@import "./components/revenue&PL/revenue&PL-table";
@import "./components/inputform/submitForReviewModal.scss";
@import "./components/inputform/copyToModal.scss";
@import "./components/login/login";
@import "./components/transformations/transformation.scss";
@import "./components/transformations/transformation-table.scss";
@import "./components/shared/dropzone/uploadDropZone.scss";
@import "./components/GMBridge/gmBridge.scss";
@import "./components/refreshNotificationDashboard/refreshNotification.scss";
@import "./components/shared/guidance-notes/guidance-notes-panel.scss";
@import "./components/inputform/updateMultipleInputsModal.scss";

html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

body {
  margin: 0;
  scroll-behavior: smooth;
  @include font-family;
}

.button {
  @include invisible-button;
}

.primary {
  @include primary-button;
}

.primary-outline {
  @include primary-button-outline;
}

.primary-outline-disabled {
  @include primary-button-outline-disabled;
}

.primary-disabled {
  @include primary-button-disabled;
}

//
.recharts-cartesian-grid line {
  stroke: #505050;
}

.scroll-add-remove {
  overflow: auto;
}
