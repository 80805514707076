.shared-dropdown {
  display: flex;
  flex-direction: column;
  position: relative;

  &__selected {
    cursor: pointer;
    align-items: center;
    column-gap: 0.5rem;
    display: flex;
    &--productSegment {
      font-size: 1.2rem;
      font-family: "open sans";
      font-weight: 400;
    }
  }
  &__year-filter {
    padding: 0.5rem;
  }

  &__options {
    background-color: $white;
    z-index: 2;
    position: absolute;
    min-width: 20rem;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    top: 2.5rem;

    &__filter {
      background-color: $white;
      z-index: 2;
      position: absolute;
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.16);
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      top: 2.5rem;
      &--year {
        min-width: 8rem;
        font-weight: 400;
        left: -3rem;
      }
      &--productSegmant {
        font-size: 1rem;
        font-weight: 400;
        min-width: 14rem;
        left: -1rem;
      }
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid $lightest-grey;
    cursor: pointer;

    &--selected {
      background-color: $lightest-blue;
    }

    &:hover {
      background-color: $lightest-grey;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}
