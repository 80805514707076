.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;
  width: 100%;

  &__filters {
    display: flex;
    margin-left: auto;
  }

  &__filter-icon {
    margin-right: 7px;
  }

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #000000;

      padding-left: 1.5rem;
    }
    &--rollForward {
      margin-right: 2rem;
      :hover {
        cursor: pointer;
      }

      &--btn {
        display: flex;
        align-items: center;
        width: inherit;
        padding: 0.5rem;
        border-radius: 5px;
        font-weight: 600;
        font-size: 14px;

        &--enabled {
          color: #007cb0;
          background: #ffffff;
          border: 1px solid #007cb0;
        }

        &--disabled {
          color: #c2c2c2;
          background: #f0f0f0;
          border: 1px solid #e0e0e0;
        }
      }
    }
  }

  &__tableContainer {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    box-sizing: border-box;

    &--header {
      height: 5rem;
      background-color: $white;
      width: 100%;
      display: flex;
      align-items: center;
      box-shadow: inset 0px -1px 0px #e0e0e0;

      &--title {
        position: absolute;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 450;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #000000;
        padding-left: 1rem;
      }
    }

    &--table {
      margin-left: 2rem;
      margin-right: 2rem;
      overflow: auto;
      height: 100%;
      border: 1px solid #ebebeb;
      background: #ffffff;
    }
  }
}
