.dashboard {
  &__filter-button {
    @include font-family;
    background-color: $secondary-light-grey;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    margin-right: 1.8rem;
    border-radius: 0.25rem;
    color: $black;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
  &__geo-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.57rem 0.8rem;
    border: none;
    margin-right: 1.8rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
  &__user-report-button {
    @include font-family;
    background-color: $primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.57rem 0.8rem;
    border: none;
    margin-right: 2rem;
    border-radius: 0.25rem;
    color: $white;
    font-weight: 400;
    line-height: 1rem;
    font-size: 0.875rem;
  }
}
