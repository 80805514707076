.login {
  overflow: auto;
  width: 100%;
  padding: 7% 12% 6% 12%;

  &__container {
    display: flex;
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__visual {
    background: #f5f5f4;
    border-radius: 25px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  &__login-button {
    padding: 6px 16px;
    gap: 16px;
    width: 77px;
    height: 36px;
    background: #007cb0;
    border-radius: 2px;
    border: #007cb0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
  }

  &__image {
    margin: 80px 30px;
  }

  &__title {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 48px;
    color: #212121;
    margin-top: 0px;
    max-width: 450px;
  }

  &__additional-info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    min-height: 50%;
    justify-content: space-between;
  }

  &__icon-container {
    display: flex;
    align-items: center;
  }

  &__icon-wrapper {
    width: 20px;
    border: 2px solid rgba(227, 227, 228, 1);
    border-radius: 100%;
    padding-top: 16px;
    padding-bottom: 18px;
    padding-left: 15px;
    padding-right: 15px;
    overflow: visible;
    display: inline-block;
    margin-right: 30px;
  }

  @media screen and (min-width: 1511px) {
    &__image {
      width: 680px;
      height: 410px;
    }
  }

  @media screen and (max-width: 1510px) {
    &__image {
      width: 580px;
    }
  }
  @media screen and (max-width: 1451px) {
    &__image {
      width: 540px;
    }
  }
  @media screen and (max-width: 1399px) {
    &__image {
      width: 500px;
    }
  }
  @media screen and (max-width: 1280px) {
    &__image {
      width: 425px;
    }
  }
}
