.dashboardTable {
  &--innerWrapper {
    width: 100%;
    display: flex;
    // overflow-x: auto;
  }

  &--loading {
    @include loading-icon;
  }

  &__spinner-container {
    margin-top: 15rem;
  }

  &__empty-container {
    margin-top: 9rem;

    &--img {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--main {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #53565a;
      }

      &--sub {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #97999b;
      }
    }
  }

  &__header {
    &-text {
      font-weight: 600;
      font-size: 0.75rem;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__row {
    background: #ffffff;
    &:hover {
      cursor: pointer;
    }
    position: relative;

    &--statusIcon {
      padding-right: 0.7rem;
      position: relative;
      bottom: 0.45rem;
    }

    &--selected {
      background: #f0f0f0;
    }

    &--tooltip--external-tab {
      left: -1rem;

      &--globalAdmin {
        left: 1.5rem;
      }
    }

    &--tooltip--export {
      left: 2.5rem;

      &--globalAdmin {
        left: 4.8rem;
      }
    }

    &--tooltip--upload-file {
      left: 1.5rem;

      &--globalAdmin {
        left: 7.4rem;
      }
    }

    &--actions--container {
      position: sticky;
      right: 0rem;
      width: fit-content;
      display: flex;
      background: #53565a;
      height: 4.5rem;
      align-items: center;
      border-radius: 48px 0px 0px 48px;
    }

    &--actions {
      width: 100%;
      justify-content: center;
      margin-left: 9px;
      display: flex;
    }

    &__edit {
      background: none;
      border: none;
      .tooltip {
        display: none;
        // position: relative;
        top: 0rem;
        left: 1.5rem;

        &__arrow {
          left: 1rem;
        }
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__external-tab {
      background: none;
      border: none;
      .tooltip {
        display: none;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__export {
      background: none;
      border: none;
      .tooltip {
        display: none;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__upload-file {
      background: none;
      border: none;
      .tooltip {
        display: none;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  tr {
    box-shadow: inset 0px -1px 0px #e0e0e0;
    align-items: center;
    width: 100%;
  }

  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 2.5rem;
  }

  th {
    padding: 0.5rem 1rem;
  }

  thead {
    position: sticky;
    background: $white;
    z-index: 1;
  }

  @media screen and (min-width: 1200px) {
    tr {
      align-items: center;
    }

    th:nth-child(1),
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4),
    th:nth-child(5),
    th:nth-child(6),
    th:nth-child(7),
    th:nth-child(8),
    th:nth-child(9),
    th:nth-child(10) {
      padding-left: 0.1rem;
      max-width: fit-content;
      padding-right: 0rem;
    }

    td:nth-child(1),
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7),
    td:nth-child(8),
    td:nth-child(9),
    td:nth-child(10) {
      max-width: fit-content;
      padding-right: 0rem;
    }
  }
}
