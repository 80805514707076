.revenue {
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 4rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__subHeader {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 56px;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
    &--noteBold {
      font-family: "Open Sans";
      font-style: bold;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }
    &--note {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;

      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 0.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }
  &__subHeaderlargeNote {
    display: flex;
    width: 100%;
    height: 90px;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &--largeNote {
      background-color: #ffffff !important;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      align-items: center;
      color: #000000;
      padding: 15px;
    }
  }
}

.scrollContainer {
  background-color: white;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  height: calc(100% - 80px);
  overflow: auto;
}
