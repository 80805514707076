.Table {
  width: 100%;

  &__actions-tooltip-text-overflow {
    padding: 10px 12px !important;
  }

  &__actions-tooltip {
    width: 100px;
  }
  &__actions-tooltip-common {
    padding-bottom: 13px !important;
  }
  &__icon-rtl {
    padding-right: 23px !important;
    background: url("../../shared/icon/icons/percent.svg") no-repeat right,
      #ffffff !important;
    background-size: 15px !important;

    &--disabled {
      padding-right: 23px !important;
      background: url("../../shared/icon/icons/percent.svg") no-repeat right,
        #f0f0f0 !important;
      background-size: 15px !important;
    }
  }

  &__table {
    width: 100%;
    border-spacing: 0;
  }

  &__email {
    text-decoration: none;
    color: inherit;
  }

  &__email:hover {
    text-decoration: underline;
  }

  &__header {
    background: $white;
    box-shadow: inset 0px -1px 0px $secondary-light-grey;
    position: sticky;
    top: 0;
    z-index: 2;
  }

  &__header-content {
    text-align: left;

    .filter {
      line-height: 1.5rem;
      visibility: hidden;
      cursor: pointer;

      &__active {
        visibility: visible;
      }
    }

    &:hover {
      .filter {
        visibility: visible;
      }
    }

    .filter-modal {
      position: absolute;
      top: -1.2rem;
      z-index: 1;

      &--right {
        left: 2rem;
      }

      &--left {
        left: -15rem;
      }
    }
  }

  &__header-text {
    font-weight: 600;
    font-size: 0.75rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    position: relative;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &__column {
    padding: 0.75rem;
  }
  &__input-container {
    box-sizing: border-box;
    outline-color: #007cb0;
    text-overflow: ellipsis;
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 4px;

    // position: absolute;
    height: 36px;
    width: 96px;
    top: calc(50% - 36px / 2);

    /* Primary / White */

    background: #ffffff;
    /* Secondary / Cool Gray 2 */

    border: 1px solid #d0d0ce;
    border-radius: 2px;
    &-selected-drag-input-icon {
      border: 2px solid #007cb0;
      border-radius: 3.5px;
      color: white;
      background-color: #007cb0;
      padding-right: 23px !important;
      background: url("../../shared/icon/icons/percent.svg") no-repeat right,
        #007cb0 !important;
      background-size: 15px !important;
    }
    &-selected-drag {
      border: 2px solid #007cb0;
      border-radius: 3.5px;
      color: white;
      background-color: #007cb0;
    }
    &-highlight {
      background: #ffffff;
      border: 3px solid #ffe785;
      border-radius: 2px;
    }
  }
  &__grade_out_input-container {
    box-sizing: border-box;
    outline-color: #007cb0;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 8px;
    grid-gap: 4px;
    gap: 4px;
    height: 36px;
    width: 96px;
    top: calc(50% - 36px / 2);

    background: #f0f0f0;
    border: 1px dashed #d0d0ce;
    border-radius: 2px;
  }
  &__error_input-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 6px 8px;
    gap: 4px;
    height: 36px;
    width: 96px;
    top: calc(50% - 36px / 2);
    background: #ffffff;
    border: 1px solid red;
    border-radius: 2px;
  }
  &__error-container {
    height: 65px;
    width: 82px;
  }
  &__large_error-container {
    height: 65px;
    width: 300px;
  }
  &__text-container {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // width: 1px;
    flex-grow: 1;
    height: 100%;
    // padding-left: 4rem;
  }

  &__column-content {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    align-items: center !important;

    .tooltip {
      left: 100%;
      top: 0;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }
  &__column-content_has_error {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;

    .tooltip {
      left: 100%;
      top: 0;
      z-index: 2;
      display: none;
    }

    &:hover {
      .tooltip {
        display: inline-block;
      }
    }
  }

  &__icon {
    width: 1.5rem;
  }
  &__container_width {
    width: 130px;
  }

  &__content-icon {
    padding-right: 0.75rem;
  }
  &__Info-icon-div {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  &__clickable {
    cursor: pointer;
  }

  &__row {
    box-shadow: inset 0px -1px 0px $secondary-light-grey;

    &-link {
      cursor: pointer;
    }

    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__row--selected {
    background-color: $lightest-blue;
    //box-shadow: inset 3px 0px 0px $primary;
  }

  &__column-text {
    font-size: 0.875rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    max-width: 100%;
    height: 100%;
    align-items: center;

    &-indentation {
      padding-left: 1rem;
    }

    .tooltip {
      left: -4rem;
      top: -200%;
    }

    &--initialsContainer {
      padding-right: 1rem;
    }

    &--initials {
      display: flex;
      height: 20px;
      width: 20px;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background: #000000;
      border-radius: 100%;
      padding: 0.5rem;
    }
  }

  &__column-subtitle {
    font-weight: 400;
    font-size: 0.75rem;
    color: $grey;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__text-icon {
    align-items: center;
  }

  &__spinner-container {
    display: flex;
    justify-content: center;
  }

  &__spinner {
    width: 2rem;
    padding: 1rem 0;
  }

  &__header-inner-content {
    padding: 0.75rem;
    display: flex;
    @include font-family;
  }

  &__header-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
    background: none;
    border: none;
    padding-right: 2.25rem;

    &--sorted {
      padding-right: 0.75rem;
    }
  }

  &__sort-icon {
    padding-right: 0.5rem;
  }

  &__keywords {
    width: 0.4rem;
    padding-right: 0;
  }

  &__checkbox-container {
    width: 1.5rem;
    padding-right: 0;
    position: relative;
  }

  th:nth-child(1),
  td:nth-child(1) {
    width: 0;
  }

  &__shortlist-note-button {
    @include primary-button-outline;
    padding: 0;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: none;
    background-color: transparent;
  }

  &__disabled_input-container {
    background-color: #f0f0f0 !important;
  }

  &__likes-wrapper {
    display: flex;
    align-content: center;
    justify-content: center;
    margin-left: 23px;
    margin-right: 17px;
    margin-top: -28px;
  }
  &__likes-relavance {
    position: absolute;
    background: #e0e0e0;
    border-radius: 100px;
    cursor: pointer;
    padding: 4px 6px 4px 6px;
    color: #757575;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
  }
  &__likes-relavance:hover {
    text-decoration: underline;
  }
  &__likes-list_top {
    position: absolute;
    box-sizing: border-box;
    right: -20px;
    top: 103%;
    z-index: 999;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: fit-content;
    max-height: 150px;
    overflow-x: auto;
  }
  &__likes-list_bottom {
    position: absolute;
    box-sizing: border-box;
    right: -20px;
    bottom: 103%;
    z-index: 999;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 2px;
    width: fit-content;
    max-height: 150px;
    overflow-x: auto;
  }

  &__popup-container {
    display: flex;
    align-items: center;
    padding: 5px;
  }

  &__popup-text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #212121;
    margin-left: 15px;
    margin-right: 10px;
  }

  &__expandIcon {
    margin-left: 10px;
  }
}
