.transformation {
  margin: 15px;
  height: calc(100% - 30px);
  flex-direction: column;
  display: flex;
  background-color: #ffffff;

  &__divider-header {
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: $white;
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    position: sticky;
    top: 0;
    z-index: 2;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.3rem;
      line-height: 1.2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }

    &--icon {
      border: none;
      background: none;
      margin-right: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__tab {
    left: 0;
    display: flex;
    background-color: $white;
    min-height: 67px;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    justify-content: space-between;

    &--item {
      display: flex;
      align-items: center;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &__liberated_hours-header {
    display: flex;
    flex-basis: fit-content;
    justify-content: start;
  }
  &__liberated_hours-divider {
    width: 0.0625rem;
    height: 1.5rem;
    background: var(--neutral-black-12, #e0e0e0);
    margin-left: 1rem;
  }
  &__liberated_hours-icon {
    margin-left: 0.75rem;
  }
}
