.modalESG {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba($black, 0.5);
  opacity: 0;
  transition: all 200ms ease-in-out;
  z-index: -1;
  overflow-y: auto;
  overflow-x: hidden;

  &--show {
    opacity: 1;
    z-index: 9999;
  }

  @media screen and (max-height: 700px) {
    &__content {
      margin-top: 6rem;
    }
  }

  &__content {
    max-width: 80%;
    max-height: 80%;
    border-radius: 0.5rem;
    transform: translateY(-50%);
    transition: all 200ms ease-in-out;
    max-height: fit-content;
    margin-bottom: 1rem;

    &--showBackground {
      background-color: $white;
    }

    &--show {
      transform: translateY(0);
    }

    &--fullscreen {
      width: 100%;
      max-height: 90vh;
      overflow: auto;
    }
  }

  &__header {
    padding: 1rem;
    display: flex;
    max-height: fit-content;
    &--withoutIcon {
      justify-content: space-between;
    }
    &--icon--container {
      margin-right: 0.6rem;
      padding-top: 0.12rem;
    }

    h1 {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
    }
  }

  &__close {
    @include invisible-button;
  }

  &__body {
    max-height: fit-content;
    border-top: 1px solid $lightest-grey;
    padding: 1rem 0;

    &--hideBorder {
      border-top: none;
    }
  }
}
