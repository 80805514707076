.configurationPanel {
  position: absolute;
  right: 0%;
  background-color: white;
  width: 30%;
  height: calc(100vh - 178px);
  top: 81px;
  z-index: 99999;
  box-shadow: 0 4px 20px 0 rgba(23, 55, 83, 0.15) !important;
  overflow-y: auto;

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 200;
      font-size: 1.7rem;
      line-height: 2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--info {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
    }
  }

  &__content {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 450;
    padding: 13px;
    font-size: 14px;
    line-height: 1.5rem;
  }
}
