.updateInputsModal {
    width: 30rem;
    height: 13rem;

    &__content {

        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
        
        &--input-container {
            position: relative;
            left: 1rem;
        }

        &--text {
            margin-left: 1rem;
            margin-right: 1rem;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &--inputbox {

            box-sizing: border-box;
            outline-color: #007cb0;

            /* Auto layout */

            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 6px 8px;
            gap: 4px;

            // position: absolute;
            height: 36px;
            width: 80px;
            top: calc(50% - 36px / 2);

            /* Primary / White */

            background: #ffffff;
            /* Secondary / Cool Gray 2 */

            border: 1px solid #d0d0ce;
            border-radius: 2px;
        }

        &--footer {
            margin-top: 8rem;
            height: 5rem;
            box-shadow: inset 0px 1px 0px #E0E0E0;
            &--actions {
                display: flex;
                flex-direction: row;
                height: 2.5rem;
                padding-top: 1.25rem;
                margin-left: 15rem;

                :hover {
                    cursor: pointer;
                }

                &--warning {
                    display: flex;
                    flex-direction: row;
                    height: 2.5rem;
                    padding-top: 0.5rem;
                    margin-left: 15rem;

                    :hover {
                        cursor: pointer;
                    }
                }

                &--confirm {
                    margin-left: 1rem;
                    background: #007CB0;
                    border: none;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #FFFFFF;
                    min-width: 6rem;
                }

                &--cancel{
                    margin-left: 1rem;
                    background: #FFFFFF;
                    border: 1px solid #007CB0;
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 24px;
                    color: #007CB0;
                    min-width: 6rem;
                }
            }
        }
    }
}