$grey: #75787b;
$dark-grey: #757575;
$light-grey: #d0d0ce;
$lightest-grey: #f0f0f0;
$secondary-light-grey: #ebebeb;
$omnia-grey: #e9e9e9;
$black: #000;
$white: #fff;
$primary: #007cb0;
$accessible-blue: #005478;
$dark-blue: #012169;
$dark-primary: #005587;
$light-blue: #a3cee0;
$lighter-blue: #e2eff4;
$lightest-blue: #f1f8fb;
$red: #da291c;
$yellow: #ffe67f;
$cool-gray: #53565a;
$green-secondary: #43b02a;
$orange: #ed8b00;
