.copyToModal {
  width: 30rem;
  height: auto;

  &__content {
    &--text {
      margin-left: 1rem;
      margin-right: 1rem;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      margin-bottom: 1rem;
      p {
        margin: 0 0 6px;
        padding: 0;
        font-weight: 600;
        line-height: 16px;
        font-size: 14px;
        color: #757575;
      }
      &--selectAll {
        margin-bottom: 5px;
        font-weight: 600;
        &--label {
          margin-left: 4px;
        }
      }
      &--checkboxes {
        margin-left: 20px;
        margin-bottom: 5px;
        &--label {
          margin-left: 4px;
        }
      }
      label {
        margin-bottom: 6px;
      }
    }

    &--footer {
      box-shadow: inset 0px 1px 0px #e0e0e0;

      &--actions {
        display: flex;
        flex-direction: row;
        height: 2.5rem;
        padding-top: 1.25rem;
        margin-left: 15rem;

        :hover {
          cursor: pointer;
        }

        &--warning {
          display: flex;
          flex-direction: row;
          height: 2.5rem;
          padding-top: 1rem;
          margin-left: 15rem;

          :hover {
            cursor: pointer;
          }
        }

        &--confirm {
          margin-left: 1rem;
          background: #007cb0;
          border: none;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #ffffff;
          min-width: 6rem;
          &--disabled {
            background: rgb(190, 189, 189);
            cursor: not-allowed !important;
          }
        }

        &--cancel {
          margin-left: 1rem;
          background: #ffffff;
          border: 1px solid #007cb0;
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: #007cb0;
          min-width: 6rem;
        }
      }
    }
  }
}
