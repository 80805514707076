$arrow-size: 5px;

.tooltip {
  position: absolute;
  left: 0;
  top: 0;
  display: inline-block;
  font-size: 12px;
  width: max-content;
  max-width: 15rem;
  pointer-events: auto;

  &__arrow {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -$arrow-size;
    border-width: $arrow-size $arrow-size $arrow-size 0;
    border-color: transparent;
    border-style: solid;
  }

  &__label {
    max-width: 18.5rem;
    padding: 0.2rem 0.5rem;
    color: $white;
    text-align: left;
    background-color: $black;
    border-radius: 0.15rem;
    overflow-wrap: break-word;
    white-space: initial;
    max-height: 12rem;
    overflow-y: auto;
  }

  &--largeTextRight {
    margin-left: $arrow-size;
    &__arrow {
      top: 9% !important;
      left: auto;
      margin-left: -$arrow-size;
      border-right-color: $black;
    }
  }

  &--right {
    margin-left: $arrow-size;

    &__arrow {
      left: auto;
      margin-left: -$arrow-size;
      border-right-color: $black;
    }
  }

  &--topRight {
    margin-left: $arrow-size;

    &__arrow {
      top: 10%;
      bottom: 1px;
      left: auto;
      margin-left: -$arrow-size;
      border-right-color: $black;
    }
  }

  &--periodRight {
    margin-left: 5rem;

    &__arrow {
      left: auto;
      margin-left: 5rem;
      border-right-color: $black;
    }
  }

  &--left {
    margin-left: -$arrow-size;

    &__arrow {
      left: auto;
      right: -$arrow-size;
      border-width: $arrow-size 0 $arrow-size $arrow-size;
      border-left-color: $black;
    }
  }

  &--top {
    margin-top: -$arrow-size;

    &__arrow {
      left: 50%;
      top: auto;
      bottom: -$arrow-size;
      border-width: $arrow-size $arrow-size 0;
      border-top-color: $black;
    }
  }

  &--bottom {
    margin-top: $arrow-size;

    &__arrow {
      left: 50%;
      top: 0;
      margin-left: -$arrow-size;
      border-width: 0 $arrow-size $arrow-size;
      border-bottom-color: $black;
    }
  }

  &--frameworkleft {
    margin-left: 2rem;

    &__arrow {
      left: auto;
      margin-left: 2rem;
      border-right-color: $black;
    }
  }
}
