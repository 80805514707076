.revenueTable {
  // height: 50rem;

  &__tableContainer {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    box-sizing: border-box;
    height: 7rem;

    &--header {
      height: 5rem;
      background-color: $white;
      width: 100%;
      display: flex;
      align-items: center;
      box-shadow: inset 0px -1px 0px #e0e0e0;

      &--title {
        position: absolute;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 450;
        font-size: 1.5rem;
        line-height: 1.5rem;
        color: #000000;
        padding-left: 1rem;
      }
    }

    &--table {
      margin-left: 2rem;
      margin-right: 2rem;
      overflow: auto;
      height: 100%;
      border: 1px solid #ebebeb;
      background: #ffffff;
    }
  }

  &--innerWrapper {
    width: 100%;
    display: flex;
    overflow: scroll;
  }

  &--loading {
    @include loading-icon;
  }

  &__spinner-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__empty-container {
    margin-top: 9rem;

    &--img {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--main {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #53565a;
      }

      &--sub {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #97999b;
      }
    }
  }

  &__header {
    &-text {
      font-weight: 600;
      font-size: 0.75rem;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__row {
    background: #ffffff;
    &:hover {
      cursor: pointer;
    }
    position: relative;

    &--statusIcon {
      padding-right: 0.7rem;
      position: relative;
      bottom: 0.45rem;
    }

    &--selected {
      background: #f0f0f0;
    }

    &--actions--container {
      position: sticky;
      right: 0rem;
      width: 9rem;
      margin-left: -9rem;
      display: flex;
      background: #53565a;
      height: 4.5rem;
      align-items: center;
      border-radius: 48px 0px 0px 48px;
    }

    &--actions {
      margin-left: 1.5rem;
      display: flex;
    }

    &__edit {
      background: none;
      border: none;
    }

    &__external-tab {
      background: none;
      border: none;
    }

    &__export {
      background: none;
      border: none;
    }
  }

  tr {
    box-shadow: inset 0px -1px 0px #e0e0e0;
    align-items: center;
    width: 100%;
  }

  td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 2.5rem;
  }

  th {
    padding: 0.5rem 1rem;
  }

  thead {
    position: sticky;
    background: $white;
    z-index: 1;
  }

  @media screen and (min-width: 1200px) {
    tr {
      align-items: center;
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 9rem;
      padding-right: 2rem;
    }
    th:nth-child(1) {
      padding-left: 0.4rem;
    }
    td:nth-child(1) {
      padding-left: 1.1rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 5rem;
      padding-right: 0rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      min-width: 5rem;
      padding-right: 0rem;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 5rem;
      padding-right: 0rem;
    }
    th:nth-child(5),
    td:nth-child(5) {
      min-width: 5rem;
      padding-right: 0rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      min-width: 5rem;
      padding-right: 0rem;
    }

    th:nth-child(2) {
      padding-left: 0.2rem;
    }
    th:nth-child(3) {
      padding-left: 0.2rem;
    }
    th:nth-child(4) {
      padding-left: 0.2rem;
    }
    th:nth-child(5) {
      padding-left: 0.2rem;
    }
    th:nth-child(6) {
      padding-left: 0.2rem;
    }
    th:nth-child(7) {
      padding-left: 0.2rem;
    }
  }
}
