.consolidationTables {
  &--innerWrapper {
    width: 100%;
    display: flex;
    // overflow: auto;
    // overflow-x: auto;
  }

  &__attrition-table-row &__header {
    top: 0px !important;
  }

  &--loading {
    @include loading-icon;
  }

  &__spinner-container {
    background-color: #ffffff;
    padding: 5rem 0rem;
  }

  &__empty-container {
    margin-top: 9rem;

    &--img {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--main {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #53565a;
      }

      &--sub {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #97999b;
      }
    }
  }

  &__header {
    top: 66px;
    &-text {
      font-weight: 600;
      font-size: 0.75rem;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__row {
    background: #ffffff;
    &:hover {
      cursor: pointer;
    }
    position: relative;

    &--statusIcon {
      padding-right: 0.7rem;
      position: relative;
      bottom: 0.45rem;
    }

    &--selected {
      background: #f0f0f0;
    }

    &--actions--container {
      //   position: sticky;
      right: 0rem;
      width: 9rem;
      margin-left: -9rem;
      display: flex;
      background: #53565a;
      height: 4.5rem;
      align-items: center;
      border-radius: 48px 0px 0px 48px;
    }

    &--actions {
      margin-left: 1.5rem;
      display: flex;
    }

    &__edit {
      background: none;
      border: none;
      .tooltip {
        display: none;
        // position: relative;
        top: 0rem;
        left: 1.5rem;

        &__arrow {
          left: 1rem;
        }
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__external-tab {
      background: none;
      border: none;
      .tooltip {
        display: none;
        left: 1.5rem;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__export {
      background: none;
      border: none;
      .tooltip {
        display: none;
        left: 4.7rem;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  tr {
    box-shadow: inset 0px 1px 0px #ebebeb, inset 0px -1px 0px #ebebeb;
    align-items: center;
    width: 100%;
  }

  td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 2.5rem;
  }

  th {
    padding-left: 0.7rem;
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: $white;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &:hover {
      background-color: $lightest-grey;
    }
  }
  thead th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: $white;
    z-index: 1;
  }

  tbody td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: $white;
    z-index: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &:hover {
      background-color: $lightest-grey;
    }
  }

  @media screen and (min-width: 1200px) {
    tr {
      align-items: center;
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(1) {
      padding-left: 1rem;
    }
    td:nth-child(1) {
      padding-left: 1rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(5),
    td:nth-child(5) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(7),
    td:nth-child(7) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(8),
    td:nth-child(8) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(9),
    td:nth-child(9) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(10),
    td:nth-child(10) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(11),
    td:nth-child(11) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(12),
    td:nth-child(12) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(13),
    td:nth-child(13) {
      min-width: 15rem;
      padding-right: 0rem;
    }
  }
  .Table__header {
    position: initial !important;
  }
}

.consolidationTables_TA {
  &--innerWrapper {
    width: 100%;
    display: flex;
  }

  &__attrition-table-row &__header {
    top: 0px !important;
  }

  &--loading {
    @include loading-icon;
  }

  &__spinner-container {
    background-color: #ffffff;
    padding: 5rem 0rem;
  }

  &__empty-container {
    margin-top: 9rem;

    &--img {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &--text {
      display: flex;
      flex-direction: column;
      align-items: center;

      &--main {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #53565a;
      }

      &--sub {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #97999b;
      }
    }
  }

  &__header {
    top: 66px;
    &-text {
      font-weight: 600;
      font-size: 0.75rem;

      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  &__row {
    background: #ffffff;
    &:hover {
      cursor: pointer;
    }
    position: relative;

    &--statusIcon {
      padding-right: 0.7rem;
      position: relative;
      bottom: 0.45rem;
    }

    &--selected {
      background: #f0f0f0;
    }

    &--actions--container {
      //   position: sticky;
      right: 0rem;
      width: 9rem;
      margin-left: -9rem;
      display: flex;
      background: #53565a;
      height: 4.5rem;
      align-items: center;
      border-radius: 48px 0px 0px 48px;
    }

    &--actions {
      margin-left: 1.5rem;
      display: flex;
    }

    &__edit {
      background: none;
      border: none;
      .tooltip {
        display: none;
        // position: relative;
        top: 0rem;
        left: 1.5rem;

        &__arrow {
          left: 1rem;
        }
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__external-tab {
      background: none;
      border: none;
      .tooltip {
        display: none;
        left: 1.5rem;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }

    &__export {
      background: none;
      border: none;
      .tooltip {
        display: none;
        left: 4.7rem;
      }

      &:hover {
        cursor: pointer;
        .tooltip {
          display: inline-block;
        }
      }
    }
  }

  tr {
    box-shadow: inset 0px -1px 0px #e0e0e0;
    align-items: center;
    width: 100%;
  }

  td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    height: 2.5rem;
  }

  th {
    padding-left: 0.8rem;
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: $white;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &:hover {
      background-color: $lightest-grey;
    }
  }
  thead th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: $white;
    z-index: 1;
  }

  tbody td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: $white;
    z-index: 1;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    &:hover {
      background-color: $lightest-grey;
    }
  }

  @media screen and (min-width: 1200px) {
    tr {
      align-items: center;
    }

    th:nth-child(1),
    td:nth-child(1) {
      min-width: 23rem;
      padding-right: 0rem;
    }
    th:nth-child(1) {
      padding-left: 2rem;
    }
    td:nth-child(1) {
      padding-left: 1rem;
    }
    th:nth-child(2),
    td:nth-child(2) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(3),
    td:nth-child(3) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(4),
    td:nth-child(4) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(5),
    td:nth-child(5) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(6),
    td:nth-child(6) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(7),
    td:nth-child(7) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(8),
    td:nth-child(8) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(9),
    td:nth-child(9) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(10),
    td:nth-child(10) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(11),
    td:nth-child(11) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(12),
    td:nth-child(12) {
      min-width: 15rem;
      padding-right: 0rem;
    }
    th:nth-child(13),
    td:nth-child(13) {
      min-width: 15rem;
      padding-right: 0rem;
    }
  }

  .Table__header {
    position: initial !important;
  }
}
