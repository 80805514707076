.drop-down {
  &__outline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: $white;
    border: 1px solid $light-grey;

    border-radius: 0.25rem;
    min-height: 1.8rem;

    &--not-empty {
      padding-top: 0;
      min-height: 2.3rem;
    }

    &--missing {
      border: 0.063rem solid #da291c;
    }

    &:focus-within {
      border-color: $primary;
    }
  }

  &__placeholder {
    position: absolute;
    color: $grey;
  }

  &__input {
    padding: 0;
    height: 1.5rem;
    border: none;
    flex-grow: 1;
    width: 1px;
    outline: none;
    z-index: 1;
    resize: none;
    background-color: transparent;
    @include font-family;
    font-size: 14px;

    &:focus {
      background-color: $white;
    }

    &--not-empty {
      margin-top: 0.5rem;
      max-height: 22px;
    }
  }

  &__input-size {
    min-height: 1.25rem;
    min-width: 1px;
    max-width: 14rem;
    visibility: hidden;
    position: absolute;
  }

  &__values {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;
  }

  &__hiddenChips {
    background-color: none;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    color: $primary;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;

    :hover {
      cursor: pointer;
    }

    &--showLess {
      color: $primary;
      position: relative;
      left: 0.5rem;
      top: 0.2rem;
    }
  }

  &__value {
    background-color: $lightest-grey;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    border-radius: 1rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    position: relative;

    &--content {
      background-color: transparent;
      margin-top: 0;

      &:first-child {
        margin-top: 0.5rem;
      }
    }

    &-tooltip {
      .tooltip {
        display: none;
        top: -0.3rem;
        z-index: 5;
        white-space: pre-wrap;

        .tooltip__arrow {
          top: -8.5%;
        }
        .tooltip__label {
          // overflow: auto;
          min-width: 10rem;
          max-width: 15rem;
          max-height: fit-content;
          transform: translateY(-80%);
          position: absolute;
          z-index: 5;
        }
      }
    }

    &:hover {
      cursor: pointer;
      z-index: 5;

      .tooltip {
        display: inline-block;
      }
    }
  }

  &__remove {
    @include invisible-button;
    background-color: $grey;
    border-radius: 1rem;
    margin-left: 0.25rem;

    path {
      fill: $white;
    }
  }

  &__chevron-button {
    @include invisible-button;

    &--not-empty {
      margin-top: 0.5rem;
    }
  }

  &__chevron-icon {
    cursor: pointer;
    transition: transform 200ms ease-in-out;
    &--open {
      transform: rotate(180deg);

      path {
        fill: $primary;
      }
    }

    &--close {
      transform: rotate(0);
    }
  }

  &__options {
    width: 100%;
    border-radius: 0.25rem;
    margin-top: 0.25rem;
    background-color: $white;
    position: absolute;
    z-index: 2;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &--show {
      max-width: 350px;
      max-height: 11.8rem;
      border: 1px solid $light-grey;
      min-height: 2.5rem;
      box-shadow: 0px 1px 4px $light-grey;
      overflow: auto;
    }

    &--hide {
      max-height: 0rem;
    }

    &--map {
      position: relative;
    }
  }

  &__loading {
    margin: 0 auto;
  }

  &__option {
    @include invisible-button;
    background-color: $white;
    padding: 0.75rem 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &--selected {
      background-color: $lightest-blue;
    }

    &:first-child {
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
    }

    &:last-child {
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem;
    }

    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__option-category {
    padding: 0.5rem 1rem;
    color: $grey;
    font-weight: 700;
  }

  &__option-value {
    text-align: left;
  }

  &__ticker {
    padding-right: 0.5rem;
    min-width: 3.5rem;
  }
}
