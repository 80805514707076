.Header-Components {
  min-width: 80rem;
  z-index: 600;

  &__header {
    min-height: 3rem;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__app {
    display: flex;

    margin-left: 3rem;
    align-items: center;

    &--4yp-logo {
      margin-top: 3px;
      margin-left: 2rem;
    }
  }

  &__app-name {
    display: flex;
    align-items: center;
    background-color: #e9e9e9;
    padding-left: 3rem;
    height: 2rem;
  }

  &__help-menu {
    display: flex;
    align-items: center;
    padding-right: 3rem;
  }

  &__menu-icon {
    position: relative;
    cursor: pointer;
    display: block;
    margin-right: 1rem;
  }

  &__support-menu-container {
    position: absolute;
    min-width: 260px;
    top: 46px;
    right: 6.8rem;
    padding: 0;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0px 1px 4px #e0e0e0;
    cursor: auto;
  }

  &__logout {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 1rem;
    margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      background-color: $lightest-grey;
    }
  }

  &__request-support {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    margin: 1rem 0rem 0.5rem 1rem;
  }

  &__request-support-link {
    padding: 0.5rem 0rem 0.5rem 1rem;
    font-size: 14px;
    font-weight: normal;
    cursor: pointer;
    &:hover {
      background-color: rgba(214, 218, 219, 0.48);
    }
  }

  &__menu-container {
    position: absolute;
    min-width: 260px;
    top: 46px;
    right: 4.7rem;
    padding: 0;
    background: #fff;
    border-radius: 2px;
    cursor: auto;
    box-shadow: 0px 1px 4px #e0e0e0;
  }

  &__user-details {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px 0;
    margin: 0 16px 8px 16px;
    font-size: 10px;
    font-weight: 600;
    box-shadow: inset 0px -1px 0px #e0e0e0;
  }

  &__user-section {
    border: 1px solid #bbbcbc;
    background: #ffffff;
    padding: 0.2rem;
    border-radius: 100%;
    align-items: center;
    cursor: pointer;
    font-size: 10px;
    font-weight: 600;
  }

  &__user-avtar {
    color: #000000;
    border: 1px solid #bbbcbc;
    background: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 24px;
    text-align: center;
  }

  &__user-name {
    font-weight: 600;
    font-size: 14px;
    margin-left: 12px;
  }

  &__logout-user {
    font-weight: 600;
    font-size: 14px;
    margin-left: 17px;
  }
}
