.guidanceNotesPanel {
  position: absolute;
  right: 0%;
  background-color: white;
  width: 30%;
  height: calc(100vh - 178px);
  top: 81px;
  z-index: 99999;
  overflow-y: auto;
  box-shadow: 0 4px 20px 0 rgba(23, 55, 83, 0.15) !important;

  &__header {
    display: flex;
    background-color: $white;
    width: 100%;
    height: 5rem;
    box-shadow: inset 0px -1px 0px #e0e0e0;

    &--text {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 200;
      font-size: 1.7rem;
      line-height: 2rem;
      display: flex;
      align-items: center;
      color: #000000;
      padding-left: 1.2rem;
    }

    &--exit {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
      background: none;
      border: none;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &__content {
    &--header {
      padding-left: 1.2rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      color: #000000;
      height: 3rem;
      box-shadow: inset 0px -1px 0px #e0e0e0;

      &--guidance {
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        &--active {
          color: #86bc25;
          box-shadow: inset 0px -3px 0px #86bc25;
        }
      }

      &--notes {
        margin-left: 2.5rem;
        height: 100%;
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        &--active {
          color: #86bc25;
          box-shadow: inset 0px -3px 0px #86bc25;
        }
      }
    }

    &--text {
      margin-top: 1rem;
      padding-left: 1.2rem;

      &--paragraph {
        padding-bottom: 1.3rem;
      }
    }
  }
}
