.FYP {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  position: relative;
  height: calc(100% - 64px - 114px - 32px);

  &__content {
    flex-grow: 1;
    margin-left: $side-menu-size;
    width: calc(100% - 3.5rem);
    height: 100%;
  }

  .loading-icon {
    @include loading-icon;
  }
}

.Downloads.content-download {
  height: calc(100% - 10rem);
  margin-top: 6rem;
}

.Downloads.dashboard-download {
  height: calc(100% - 6.7rem);
  margin-top: 6rem;
}