.inputform {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f7f7f7;

  &--double-arrow {
    margin-left: 15px;
    cursor: pointer;
  }

  &__save-text {
    width: inherit !important;
  }

  &__side-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__side-bar-onclose {
    width: 172px;
    height: 21px;
    margin-top: 90px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    transform: rotate(-90deg);
  }

  &__tooltip {
    position: relative;
  }

  &__tooltip &__tooltiptext {
    font-size: 12px;
    visibility: hidden;
    width: 220px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 20%;
    left: 100%;
  }

  &__tooltip &__tooltiptext::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  }
  &__tooltip:hover &__tooltiptext {
    visibility: visible;
  }

  &__filters {
    display: flex;
    margin-left: auto;
  }

  &__filter-icon {
    margin-right: 7px;
  }

  &__header {
    display: flex;
    background-color: $white;
    padding-top: 15px;
    padding-bottom: 15px;
    box-shadow: inset 0px -1px 0px #e0e0e0;
    justify-content: space-between;

    &--title {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 1.5rem;
      display: flex;
      align-items: center;
      color: #000000;

      padding-left: 1.5rem;
    }
    &--actions {
      display: flex;
      margin-right: 3rem;
      align-items: center;
    }

    &--status {
      display: flex;
      margin-right: 2rem;

      &--icon {
        margin-right: 0.5rem;
      }

      &--text {
        font-family: "Open Sans";
        font-style: normal;
        color: #212121;
      }
    }

    &--save {
      margin-right: 2rem;
      :hover {
        cursor: pointer;
      }

      &--btn {
        display: flex;
        align-items: center;
        width: inherit;
        width: 176px;
        height: 36px;
        border-radius: 2px;
        font-weight: 600;
        font-size: 14px;

        &--enabled {
          color: #007cb0;
          background: #ffffff;
          border: 1px solid #007cb0;
        }

        &--disabled {
          color: #c2c2c2;
          background: #f0f0f0;
          border: 1px solid #e0e0e0;
        }
      }
    }

    &--export {
      &--btn {
        border: none;
        background: none;

        &--enabled {
          :hover {
            cursor: pointer;
          }
        }
      }
      .tooltip {
        position: absolute;
        display: none;
        right: 10rem;
      }

      &:hover {
        .tooltip {
          display: inline-block;
        }
      }
    }

    &--submit {
      :hover {
        cursor: pointer;
      }
      padding-right: 1rem;
      &--btn {
        width: 176px;
        height: 36px;
        border: 1px solid #e0e0e0;
        border-radius: 2px;

        &--disabled {
          color: #c2c2c2;
          background: #f0f0f0;
        }

        &--enabled {
          background: #007cb0;
          color: #ffffff;
        }
      }
    }
  }

  &__sidebar {
    display: flex;
    height: 100%;
    max-height: calc(100% - 68px);
  }
  &__dashboard__sidebar {
    display: flex;
    height: 100%;
    background-color: #f7f7f7;
    max-height: calc(100% - 77px);
  }

  &__navigation-panel {
    z-index: 99 !important;
    background-color: white;
    transition: width 200ms ease-in-out;
    z-index: 0;
    height: auto;
    &--progress {
      padding: 10px 20px 5px 20px;
      box-shadow: inset 0px -1px 0px #e0e0e0;
    }
    &--item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px 20px 20px 20px;
      box-shadow: inset 0px -1px 0px #e0e0e0;
    }
  }

  &__navigation-panel-scroll {
    height: calc(100% - 70px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__icon-text {
    display: flex;
    align-items: center;
    margin-top: -5;
  }

  &__sub-items {
    margin-left: 43px;
    cursor: pointer;
  }
}
