.metricsModel {
  &__main-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__main-container .drop-down__options {
    position: inherit;
  }
  &__drop-down {
    margin-top: 1rem;
    position: relative;

    &:first-child {
      margin-top: 0;
    }
  }
  &__metrics-container {
    overflow: auto;
    max-height: 300px;
    width: 600px;
    margin: 0px 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 2px;
  }

  &__label {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #757575;
    margin-left: 10px;
  }
  &__label-after {
    margin-left: 0px;
  }
  &__label-after::after {
    content: " *";
    color: red;
  }
  &__icon-rtl {
    margin-top: 5px;
    padding-right: 23px !important;
    background: url("../shared/icon/icons/search.svg") no-repeat right, #ffffff !important;
    background-size: 15px !important;
    box-sizing: border-box;
    width: 300px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #d0d0ce;
    border-radius: 2px;

    &:focus {
      outline: 2px solid #007cb0;
    }
  }

  &__metrics-header {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 0px 10px 10px 10px;
  }

  &__collapse {
    margin-right: 20px;
    margin-left: 5px;
  }

  &__expand {
    margin-left: 5px;
  }

  &__text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #007cb0;
  }

  &__text-enabled {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #007cb0;
  }

  &__text-disabled {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #c2c2c2;
  }

  &__currency-container {
    width: 355px;
    margin: 10px 10px;
  }

  &__geography-list {
    display: flex;
  }

  &__icon {
    margin-left: -2px;
    margin-top: -3px;
  }

  &__icon-margin {
    margin-right: 5px;
  }

  &__hover {
    cursor: pointer;
  }

  &__subconsolidation-container {
    display: flex;
    margin-top: 3px;
    margin-left: 10px;
  }

  &__subconsolidation-geos {
    margin-top: 3px;
    margin-left: 25px;
  }

  &__cancel-button {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 16px;
    width: 86px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #007cb0;
    border-radius: 2px;
    flex: none;
    order: 2;
    align-self: flex-start;
    flex-grow: 0;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #007cb0;
    margin-right: 20px;
  }
  &__apply-button-disabled {
    background: #f0f0f0;
    border: 1px solid #f0f0f0;
    color: #c2c2c2;
  }

  &__apply-button-enabled {
    background: #007cb0;
    color: #ffffff;
    border: 1px solid #007cb0;
  }

  &__button-common {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    gap: 16px;
    width: 75px;
    height: 36px;
    border-radius: 2px;
    flex: none;
    order: 3;
    align-self: flex-start;
    flex-grow: 0;
  }

  &__button-container {
    display: flex;
    justify-content: space-between;
    padding: 20px 10px 0px 35px;
    box-shadow: inset 0px 1px 0px #e0e0e0;
    align-items: center;
  }

  &__cancel-apply-button {
    display: flex;
  }

  &__all-memberFirms {
    margin-left: -3px;
    margin-bottom: 5px;
  }

  &__text-styling {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
  }

  &__total-geos {
    margin-left: 4px;
  }
}
